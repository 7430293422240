import { createRouter, createWebHashHistory } from 'vue-router'

function load(component: string) {
  return () => import(`@/views/${component}.vue`)
}

export function setupRouter() {
  const routes = [
    {
      path: '/',
      name: 'Home',
      component: load('Home'),
    },
    {
      path: '/order/:orderId',
      name: 'Order',
      component: load('Order'),
    },
    {
      path: '/orders',
      name: 'Orders',
      component: load('Orders')
    },
    {
      path: '/p',
      name: 'Pending',
      component: load('Pending')
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/'
    }
  ]

  const router = createRouter({
    history: createWebHashHistory(),
    routes
  })

  return router
}
